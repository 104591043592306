export const environment = {
	production: false,
	env: "dev",
	/** Note the below is not currently prod..delete this comment when it is */
	site: {
		baseUrl: "https://waihona.dev-kanaeokana.net",
	},
	analytics: {
		type: "none",
		enabled: false,
		idCode: ""
	},
	/** TODO: Update algolia dev environment to NON-PRODUCTION INSTANCE */
	algolia: {
		appId: 'AVLHFP39QJ',
		apiKey: '6a70280270d1af0301e87cc789a10db8'
	},
	cloudinary: {
		key: "dev-waihona"
	},
	apiRoot: 'https://dev-kanaeokana.net',
	auth: {
		clientId: '44BuhkQtHaBD8MAUZwgnR4sv4G0rChuM',
		clientDomain: 'login.dev-waihona.net', // e.g., you.auth0.com
		audience: 'https://dev-kanaeokana.net', // e.g., http://localhost:1337/
		redirect: 'https://dev-waihona.net/#/callback',
		logoutUri: 'https://dev-waihona.net/#/logout',
		scope: 'openid profile email',
	},
	firebase: {
		apiKey: "AIzaSyBLXdN1hXBOJRh4AKWesGBrSzoGqhXFOok",
		authDomain: "dev-waihona.firebaseapp.com",
		databaseURL: "https://dev-waihona.firebaseio.com",
		projectId: "dev-waihona",
		storageBucket: "dev-waihona.appspot.com",
		messagingSenderId: "679699880825"
	},
	zapier: {
		sendFeedback: "https://hooks.zapier.com/hooks/catch/2945950/719d10/",
		newUserNotification: "https://hooks.zapier.com/hooks/catch/2945950/o21rz64/"
	},
	"site-config": {
		environment: "development",
		inviteCodesRequiredForRegistration: true,
		loginRequiredForSiteAccess: true
	}
};
